exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adm-test-pages-js": () => import("./../../../src/pages/adm/test-pages.js" /* webpackChunkName: "component---src-pages-adm-test-pages-js" */),
  "component---src-pages-control-productos-bazar-js": () => import("./../../../src/pages/control/productos-bazar.js" /* webpackChunkName: "component---src-pages-control-productos-bazar-js" */),
  "component---src-pages-control-productos-bazar-old-js": () => import("./../../../src/pages/control/productos-bazar-old.js" /* webpackChunkName: "component---src-pages-control-productos-bazar-old-js" */),
  "component---src-pages-control-productos-odoo-extra-js": () => import("./../../../src/pages/control/productos-odoo-extra.js" /* webpackChunkName: "component---src-pages-control-productos-odoo-extra-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marcas-mezcal-js": () => import("./../../../src/pages/marcas-mezcal.js" /* webpackChunkName: "component---src-pages-marcas-mezcal-js" */),
  "component---src-pages-mezcales-js": () => import("./../../../src/pages/mezcales.js" /* webpackChunkName: "component---src-pages-mezcales-js" */),
  "component---src-pages-mezcales-test-js": () => import("./../../../src/pages/mezcales-test.js" /* webpackChunkName: "component---src-pages-mezcales-test-js" */),
  "component---src-pages-mi-cuenta-js": () => import("./../../../src/pages/mi-cuenta.js" /* webpackChunkName: "component---src-pages-mi-cuenta-js" */),
  "component---src-pages-portafolio-js": () => import("./../../../src/pages/portafolio.js" /* webpackChunkName: "component---src-pages-portafolio-js" */),
  "component---src-pages-preview-[codigo]-js": () => import("./../../../src/pages/preview/[codigo].js" /* webpackChunkName: "component---src-pages-preview-[codigo]-js" */),
  "component---src-pages-preview-prod-id-edit-js": () => import("./../../../src/pages/preview/[prod_id]/edit.js" /* webpackChunkName: "component---src-pages-preview-prod-id-edit-js" */),
  "component---src-pages-sitios-mezcal-js": () => import("./../../../src/pages/sitios-mezcal.js" /* webpackChunkName: "component---src-pages-sitios-mezcal-js" */),
  "component---src-pages-todoherramientas-reviews-js": () => import("./../../../src/pages/todoherramientas/reviews.js" /* webpackChunkName: "component---src-pages-todoherramientas-reviews-js" */)
}

