import React from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import AuthProvider from "acciondigital-basic-theme/src/context/AuthContext"

import infoSitio from "../utils/infoSitio"
import SearchProvider from "../SearchContext"
import useSiteMetadata from "acciondigital-basic-theme/src/hooks/useSiteMetadata"

const { netlifySiteId: sitio } = infoSitio

const queryClient = new QueryClient()

export const RootElement = ({ children }) => {
  const {PRODUCTOS_BAZAR} = useSiteMetadata()
  
  return (
    <AuthProvider sitio={sitio}>
      <QueryClientProvider client={queryClient}>
        <SearchProvider urlBusqueda={PRODUCTOS_BAZAR}>{children}</SearchProvider>
      </QueryClientProvider>
    </AuthProvider>
  )
}

export default RootElement
