import { useStaticQuery, graphql } from "gatsby"
const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            #telefono
            #losMuralesAPI
            siteId
            ASSIST_PROD_ODOO_EXTRA_API
            PRODUCTOS_BAZAR
            PRODUCTOS_BAZAR_BUSQUEDA
          }
        }
      }
    `
  )
  
  return site.siteMetadata
}

export default useSiteMetadata
