import React, { createContext, useState, useCallback } from "react"
import axios from "axios"

import useSiteMetadata from "acciondigital-basic-theme/src/hooks/useSiteMetadata"

export const SearchContext = createContext()

const SearchProvider = ({ urlBusqueda, children }) => {

//   const PRODUCTOS_BAZAR =
//     "http://localhost:5001/igpingroute/us-central1/productos_odoo"

  // const {PRODUCTOS_BAZAR: urlBusqueda} = useSiteMetadata()

  const [productos, setProductos] = useState([])

  const getProductos = useCallback(async () => {
    setProductos([])
    axios.get(urlBusqueda).then(result => {
      const datos = result.data
      console.debug(
        "🚀 ~ file: SearchContext.js:21 ~ getProductos ~ productos:",
        result.data.length
      )
      setProductos(datos)
    })
  }, [urlBusqueda])

  const iniProductos = useCallback(async () => {
    if (productos.length === 0) getProductos()
  }, [productos, getProductos])

  return (
    <SearchContext.Provider
      value={{
        productos,
        getProductos,
        iniProductos,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export default SearchProvider
